import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import classNames from 'classnames';

import { HeatPump } from '+shared/basicComponents/Icons';
import { DetailList, StatusTile, StatusTileColors } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { Heatpump } from '+shared/store/site/types/siteLiveStateV2.interface';

import './CustomerHeatPumpWidget.scss';

type Props = {
  heatpump: Heatpump;
  className?: ClassValue;
  link?: () => void;
};

const { widgetTitle, connection } = T.customerSingle.heatPump.heatPumpStatus;

export const CustomerHeatPumpWidget = ({ className, heatpump, link }: Props) => {
  const { connectionState } = heatpump;
  const isOnline = connectionState === 'online';

  return (
    <Widget
      className={classNames('c-customer-overview__tile', className)}
      icon={<HeatPump className="heat-pump__icon" />}
      heading={I18n.t(widgetTitle)}
      link={link}
    >
      <div className="heatpump__wide-row">
        <DetailList
          list={[
            {
              label: I18n.t(connection),
              value: (
                <span className="heat-pump__status-tile">
                  <StatusTile
                    label={connectionState}
                    color={isOnline ? StatusTileColors.GREEN : StatusTileColors.GRAY}
                  />
                </span>
              ),
            },
          ]}
        />
      </div>
    </Widget>
  );
};
