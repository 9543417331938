import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import * as classNames from 'classnames';

import { DetailList } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { getStatus } from '+shared/store/query/query.utils';
import { SiteCharger } from '+shared/store/site';

interface Props {
  className?: ClassValue;
  queryStatus: ReturnType<typeof getStatus>;
  charger: SiteCharger;
}

export const CustomerChargerWidget: React.FC<Props> = ({ className, queryStatus, charger }) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={'Charger'}
    heading={I18n.t(T.customerSingle.overview.sonnenCharger.headline)}
  >
    {queryStatus.pending ? (
      <Loader />
    ) : (
      <DetailList
        list={[
          {
            label: I18n.t(T.customerSingle.overview.sonnenCharger.serialNumber),
            value: charger.serialNumber,
          },
        ]}
      />
    )}
  </Widget>
);
