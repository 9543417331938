import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { FormCheckbox, Icon, Modal, ModalLayout } from '@sonnen/shared-web';

import { CountryCode } from 'src/types/country.type';

import { Button, ButtonSize, ButtonStatus, ButtonType } from '+shared/components/Button';
import { BatteryOperatingMode, BatteryOperatingModeOptions } from '+shared/store/site/types';

import { getInfoAndWarning } from './BatteryOperatingModeConfirmModal.helper';

import './BatteryOperatingModeConfirmModal.component.scss';

interface Props {
  isOpen: boolean;
  newOperatingMode: BatteryOperatingMode | undefined;
  onCancel: () => void;
  onConfirm: () => void;
  userCountry: CountryCode;
}
const { cancelButton, confirmationButton, confirmationCheckbox } =
  T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.confirmationModal;

export const BatteryOperatingModeConfirmModal: React.FC<Props> = ({
  isOpen,
  newOperatingMode,
  onCancel,
  onConfirm,
}) => {
  const [customerInformed, setCustomerInformed] = React.useState(false);

  const onConfirmClick = (): void => onConfirm();

  const onCancelClick = () => {
    setCustomerInformed(false);
    onCancel();
  };

  const toggleCustomerInformed = () => setCustomerInformed(!customerInformed);

  const { info, warning } = getInfoAndWarning(newOperatingMode);

  return (
    <Modal isOpen={isOpen} onClose={onCancelClick} size="medium">
      <ModalLayout
        footer={
          <div className="battery-operating-mode-confirm-modal__footer">
            <Button
              label={I18n.t(cancelButton)}
              type={ButtonType.TERTIARY}
              size={ButtonSize.SMALL}
              onClick={onCancelClick}
            />
            <Button
              label={I18n.t(confirmationButton)}
              size={ButtonSize.SMALL}
              type={ButtonType.PRIMARY}
              disabled={!customerInformed}
              status={!customerInformed ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
              onClick={onConfirmClick}
            />
          </div>
        }
      >
        <div className="battery-operating-mode-confirm-modal">
          <div className="battery-operating-mode-confirm-modal__icon-stack">
            <Icon.SonnenBattery
              className={'battery-operating-mode-confirm-modal__icon-self-consumption'}
            />
            {newOperatingMode === BatteryOperatingModeOptions.MODULE_EXTENSION && (
              <Icon.SonnenBattery
                className={'battery-operating-mode-confirm-modal__icon-module-extension'}
              />
            )}
          </div>
          <div className="battery-operating-mode-confirm-modal__info-wrapper">
            <div className="battery-operating-mode-confirm-modal__info" data-testid="info">
              {I18n.t(info)}
            </div>
            {warning && (
              <div className="battery-operating-mode-confirm-modal__warning" data-testid="warning">
                {I18n.t(warning)}
              </div>
            )}
          </div>
          <div className="battery-operating-mode-confirm-modal__confirmation-container">
            <FormCheckbox
              name="customer-informed-confirmation"
              checked={customerInformed}
              onClick={toggleCustomerInformed}
              label={
                <div className="battery-operating-mode-confirm-modal__confirmation">
                  {I18n.t(confirmationCheckbox)}
                </div>
              }
            />
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};
