import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { DetailList } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { useLocaleContext } from '+utils/react/locale.provider';

import { factorizeHeaterDetailList } from './HeaterCard.helper';
import { HeaterCard as Heater } from './types/HeaterCard.types';

interface Props {
  heater: Heater;
}

export const HeaterCard: React.FC<Props> = ({ heater }) => {
  const { locale } = useLocaleContext();

  return (
    <Widget icon={'Heater'} heading={I18n.t(T.customerSingle.batteryDetails.heater.headline)}>
      <DetailList list={factorizeHeaterDetailList(locale)(heater)} filterNil={true} />
    </Widget>
  );
};
