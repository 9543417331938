import { Data, MergedData } from '@coolio/json-api';

import { MeasurementMethod } from './siteMeasurements.interface';

export enum BatteryInverterState {
  STANDBY = 'standby',
  CHARGING = 'charging',
  DISCHARGING = 'discharging',
}

type InverterStatus = 'offgrid' | 'ongrid';
type FlexActivity =
  | 'flex_negative'
  | 'flex_positive'
  | 'standby_charge_avoidance'
  | 'standby_discharge_avoidance'
  | 'no_flex_activity';

export enum BatteryOperatingModeOptions {
  SELF_CONSUMPTION = 'Self-Consumption',
  MODULE_EXTENSION = 'Battery-Module-Extension',
  OPTIMIZATION = 'Optimization',
  ENERGY_RESERVE = 'Energy Reserve',
  CELL_BALANCING = 'Cell balancing',
  MANUAL = 'Manual',
  PEAK_SHAVING = 'Peak-Shaving',
  INTELLIGENT_MODE = 'Intelligent-Mode',
  PV_SIMULATION = 'PV-Simulation',
  US_BACKUP_POWER = 'US-Backup-Power',
  US_AUTOMATIC = 'US-Automatic',
  FINAL_INSPECTION = 'Final-Inspection',
  US_TIME_OF_USE = 'US-Time-Of-Use',
  FULL_CHARGE = 'Full-Charge',
  US_NET_ZERO_PV = 'US-Net-Zero-Pv',
  COMPLETELY_OFF_GRID_MODE = 'Completely off-grid mode',
  SLAVE_MODE = 'Slave mode',
}

export type BatteryOperatingMode = BatteryOperatingModeOptions | null;

export interface SiteLiveStateAttributes {
  measurementMethod: MeasurementMethod;
  productionPower: number;
  productionPowerKw: number;
  consumptionPower: number;
  consumptionPowerKw: number;
  gridFeedin: number;
  gridFeedinKw: number;
  gridPurchase: number;
  gridPurchaseKw: number;
  backupActive: boolean;
  batteryCharging: number;
  batteryChargingKw: number;
  batteryDischarging: number;
  batteryDischargingKw: number;
  batteryUsoc: number;
  batteryOperatingMode: BatteryOperatingMode;
  batteryInverterState: BatteryInverterState;
  batteryInverterStatus: InverterStatus;
  pvReduction: boolean | null;
  independent: boolean;
  heaterPower: number;
  heaterWaterTemp: number;
  timestamp: string;
  gridServiceActive: boolean;
  online: boolean | null;
  flexActivity: FlexActivity;
  batterySerialNumber: string;
}

export type SiteLiveStateData = Data<SiteLiveStateAttributes>;
export type SiteLiveState = MergedData<SiteLiveStateData>;
