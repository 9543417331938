import { CONTRACT_ACTIONS, ContractActions } from '+shared/store/contract/contract.actions';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';

import { CUSTOMER_ACTIONS, CustomerActions } from '../../store/+customer.actions';
import { ContractPageState, GET_CONTRACT_QUERY, initialState } from './+contract.state';

export const reducer = createReducer<ContractPageState, ContractActions | CustomerActions>(
  (state = initialState, action): ContractPageState => {
    switch (action.type) {
      case CONTRACT_ACTIONS.SET_CONTRACT:
        return {
          ...state,
          contract: action.contract,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [GET_CONTRACT_QUERY]);
    }
  }
);
