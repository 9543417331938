import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web/src/components/Status/Status.component';

import { EnergyUnit } from '+app/App.constants';
import { CountryFeatureName } from '+config/countryFlags';
import { DetailListType } from '+shared/components';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import {
  ContractType,
  formatContractName,
  isDynamicTariffOrSonnenStrom,
} from '+shared/store/contract/contract.helpers';
import {
  Contract,
  MeterInstallationAttributes,
} from '+shared/store/contract/types/contract.interface';
import { getContractWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { insertIf } from '+utils/array.util';
import { formatDate } from '+utils/format.util';

import { CustomerContractWidgetStatusTile } from '../../components/CustomerContractWidgetStatusTile';

type LabelValueType = Pick<DetailListType, 'label' | 'value'>;
const missingValue = '—';

const getProductName = (contract: Contract): LabelValueType => ({
  label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.first),
  value: formatContractName(contract.name),
});

const getOrderedAt = (contract: Contract): LabelValueType => ({
  label: I18n.t(T.customerSingle.overview.sonnenEnergy.orderDate),
  value: contract.orderedAt ? formatDate(contract.orderedAt) : missingValue,
});

const getUsageAllowance = (contract: Contract): LabelValueType => ({
  label: I18n.t(T.customerSingle.overview.sonnenFlat.quotaIncl),
  value: contract.amountOfFreeEnergy
    ? `${contract.amountOfFreeEnergy} ${EnergyUnit.KWH}`
    : missingValue,
});

const getContractNumber = (contract: Contract): LabelValueType => ({
  label: I18n.t(T.customerSingle.overview.sonnenFlat.contractNumber),
  value: contract.contractNumber,
});

const getDeliveryStartDate = (contract: Contract): LabelValueType => ({
  label: I18n.t(T.customerSingle.overview.sonnenFlat.deliveryStart),
  value: contract.deliveryStartAt ? formatDate(contract.deliveryStartAt) : missingValue,
});

type FactorizeContractDetailsInput = {
  contract: Contract;
  meterInstallation?: MeterInstallationAttributes;
  showProductName?: boolean;
};

export const factorizeContractDetails = ({
  contract,
  meterInstallation,
  showProductName = false,
}: FactorizeContractDetailsInput): LabelValueType[] => {
  const { meterInstallationWarningFactory, missingPvRemoteControl } = getContractWarnings({
    contract,
    meterInstallation,
  });
  const contractNotificationsEnabled = isRolloutLimitedCountryFeatureEnabled(
    CountryFeatureName.CONTRACT_NOTIFICATIONS
  );
  const dynamicTariffOrSonnenStrom = isDynamicTariffOrSonnenStrom(contract);
  const isItalianContract = contract.contractType === ContractType.SONNEN_FLAT_IT;
  const insertWarnings =
    !dynamicTariffOrSonnenStrom && !isItalianContract && contractNotificationsEnabled;

  const meterInstallationWarning = {
    label: I18n.t(T.customerSingle.overview.contract.meter),
    value: meterInstallationWarningFactory ? (
      <CustomerContractWidgetStatusTile
        message={meterInstallationWarningFactory.message}
        messageExtended={meterInstallationWarningFactory.messageExtended}
        type={meterInstallationWarningFactory.type || StatusType.DEFAULT}
      />
    ) : (
      missingValue
    ),
  };
  const missingPvRemoteControlWarning = {
    label: I18n.t(T.customerSingle.overview.contract.fst),
    value: missingPvRemoteControl ? (
      <CustomerContractWidgetStatusTile
        message={missingPvRemoteControl.message}
        messageExtended={missingPvRemoteControl.messageExtended}
        type={missingPvRemoteControl.type || StatusType.DEFAULT}
      />
    ) : (
      missingValue
    ),
  };

  return [
    ...insertIf(showProductName, getProductName(contract)),
    getOrderedAt(contract),
    ...insertIf(!dynamicTariffOrSonnenStrom, { ...getUsageAllowance(contract) }),
    getContractNumber(contract),
    getDeliveryStartDate(contract),
    ...insertIf(insertWarnings, meterInstallationWarning),
    ...insertIf(insertWarnings, missingPvRemoteControlWarning),
  ];
};
