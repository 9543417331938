import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { HttpResponseError } from '@coolio/http';
import { isEmpty, isNil } from 'lodash/fp';
import { from } from 'rxjs';

import {
  backupPowerBuffer,
  getBatteryIntelligentCharging,
  getPvGridFeedInLimit,
} from '+app/+customer/+battery/store/+battery.selectors';
import {
  POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY,
  POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY,
} from '+app/+customer/+battery/store/+battery.state';
import { mapBatteryOperatingModeToLabel } from '+app/+customer/store/+customer.helper';
import { DAYS_IN_WEEK, EnergyUnit, HOURS_IN_DAY, MINUTES_IN_HOUR } from '+app/App.constants';
import { LeadOffer } from '+shared/store/lead/types';
import { dateUtil } from '+utils/date.util';
import { formatEnergy } from '+utils/format.util.old';
import { trim } from '+utils/string.util';
import { UtilDuration } from '+utils/UtilDuration';

import { LeadProductBattery } from '../lead/types/leadProductBattery.interface';
import {
  BatteryInverterState,
  BatteryOperatingMode,
  BatteryOperatingModeOptions,
  SiteLiveState,
} from '../site/types/siteLiveState.interface';
import { StoreState } from '../store.interface';
import {
  Battery,
  BatteryAttributes,
  BatteryIntelligentCharging,
  BatteryWithProduct,
} from './types/battery.interface';

export const getOfflineSince = (timestamp: string | null): string => {
  if (!timestamp) return 'Offline';

  const lastKnownTimestamp = dateUtil.of(timestamp);
  const duration = UtilDuration.ofMilliseconds(
    dateUtil.getDifference(lastKnownTimestamp, dateUtil.now(), 'milliseconds')
  );
  const minutes = Math.floor(duration.asMinutes());
  const hours = Math.floor(duration.asHours());
  const days = Math.floor(duration.asDays());
  const weeks = Math.floor(duration.asWeeks());

  if (minutes < MINUTES_IN_HOUR) return I18n.t(T.warning.batteryOfflineMinutes, { minutes });

  if (hours < HOURS_IN_DAY) return I18n.t(T.warning.batteryOfflineHours, { hours });

  if (days < DAYS_IN_WEEK) return I18n.t(T.warning.batteryOfflineDays, { days });

  return I18n.t(T.warning.batteryOfflineWeeks, { weeks });
};

export const getBatteryModelWithCapacity = ({ product, batteryCapacity }: Battery) =>
  trim`
    ${getBatteryProductName(product)}
    ${
      !isNil(batteryCapacity)
        ? `(${formatEnergy({
            whValue: batteryCapacity,
            unit: EnergyUnit.KWH,
            precision: 1,
            minPrecision: 0,
          })})`
        : undefined
    }
  `;

export const visibleBatterySystemsStatuses = ['installed', 'registered'];

export const isBatteryInStandby = (siteLiveState: SiteLiveState | undefined): boolean =>
  !!siteLiveState && siteLiveState.batteryInverterState === BatteryInverterState.STANDBY;

export const isBatteryInstalled = (battery: Battery | undefined): boolean =>
  !!battery && !!battery.installationDate;

export const hasPvSystem = (battery: Battery | undefined): boolean =>
  !!battery && !isNil(battery.pvPeakPower) && !isNil(battery.pvGridFeedInLimit);

export const hasBatteryBackupDevice = (battery: Battery | undefined): boolean =>
  !!battery && !isNil(battery.backupDeviceType) && battery.backupDeviceType !== 'none';

export const findBatteryInBatteryList = (
  offer: LeadOffer,
  productBatteryList: LeadProductBattery[]
): LeadProductBattery | undefined =>
  productBatteryList.find((battery) => battery.id === offer.battery);

export const isAnyBatteryOnSiteVisible = (batterySystems: BatteryAttributes[]) =>
  !isEmpty(
    batterySystems.filter((battery) => visibleBatterySystemsStatuses.includes(battery.assetStatus))
  );

export const getBatteryProductName = (product: Battery['product']): string =>
  isNil(product?.quoteNameDe)
    ? I18n.t(T.customerSingle.batteryDetails.defaultBattery)
    : product.quoteNameDe;

export const prepareBatteryIntelligentChargingNotification = (
  batteryIntelligentCharging: BatteryIntelligentCharging
): string => {
  switch (batteryIntelligentCharging) {
    case 'on':
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .notificationWhenActivate
      );

    case 'off':
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .notificationWhenDeactivate
      );

    case null:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .notificationWhenError
      );

    default:
      return '';
  }
};

export const prepareBackupPowerBufferNotification = (backupPowerBuffer: number | null): string => {
  const {
    notificationWhenError,
    notificationWhenSuccess,
    notificationWhenSuccessfulBatterySwitchOff,
  } = T.customerSingle.batteryDetails.batteryOperations.changeBackupBuffer;

  let bannerText = I18n.t(notificationWhenError);

  if (typeof backupPowerBuffer === 'number') {
    bannerText =
      backupPowerBuffer === 0
        ? I18n.t(notificationWhenSuccessfulBatterySwitchOff)
        : I18n.t(notificationWhenSuccess, { value: backupPowerBuffer });
  }

  return bannerText;
};

export const prepareBatteryPvFeedInLimitNotification = (pvGridFeedInLimit: number | null): string =>
  typeof pvGridFeedInLimit === 'number'
    ? I18n.t(
        T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation.notifications
          .success,
        { value: pvGridFeedInLimit }
      )
    : I18n.t(
        T.customerSingle.pvSystemsAndMeters.pvSystemOperations.pvFeedInOperation.notifications
          .failed
      );

export const isRequestedValueSet = (
  queryKey: string,
  battery: BatteryWithProduct,
  state: StoreState
): boolean => {
  if (queryKey === POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY) {
    return battery.prognosisCharging !== getBatteryIntelligentCharging(state);
  }
  if (queryKey === POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY) {
    return battery.backupPowerBuffer !== backupPowerBuffer(state);
  } else return battery.pvGridFeedInLimit !== getPvGridFeedInLimit(state);
};

export const prepareRemoteControlNotification = (
  queryKey: string,
  battery: BatteryWithProduct | null,
  queryStatus: 'success' | 'failure'
): string => {
  if (queryKey === POST_BATTERY_INTELLIGENT_CHARGING_POLLING_QUERY) {
    return prepareBatteryIntelligentChargingNotification(
      queryStatus === 'success' ? battery!.prognosisCharging : null
    );
  }
  if (queryKey === POST_BATTERY_BACKUP_BUFFER_POLLING_QUERY) {
    return prepareBackupPowerBufferNotification(
      queryStatus === 'success' ? battery!.backupPowerBuffer : null
    );
  }
  return prepareBatteryPvFeedInLimitNotification(
    queryStatus === 'success' ? battery!.pvGridFeedInLimit : null
  );
};

export const prepareBatteryOperatingModeNotification = (
  operatingMode: BatteryOperatingMode | null
): string => {
  switch (operatingMode) {
    case BatteryOperatingModeOptions.SELF_CONSUMPTION:
    case BatteryOperatingModeOptions.MODULE_EXTENSION:
    case BatteryOperatingModeOptions.OPTIMIZATION:
    case BatteryOperatingModeOptions.US_TIME_OF_USE:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode
          .notificationWhenSuccess,
        { mode: mapBatteryOperatingModeToLabel(operatingMode) }
      );
    case null:
      return I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.notificationWhenError
      );
    default:
      return '';
  }
};

export const ErrorTitle = {
  WRONG_DATA: 'Value has an invalid structure, please check if the schedule is correct',
  WRONG_THRESHOLD_VALUE: 'Value Threshold p max muss kleiner oder gleich ', // 11500 sein,
};

export const findIndex = (searchString: string) => {
  const regex = /\d+/g;
  const matches = searchString.match(regex);

  return matches![0].toString();
};

export const isThresholdValidated = (error: HttpResponseError) => {
  // TODO: Slice error.title and compare only with first part. Additionally check error code
  return from(
    error.response.parsedBody().then((res) => {
      if (
        res.errors.some((error: { title: string }) =>
          error.title.includes(ErrorTitle.WRONG_THRESHOLD_VALUE)
        )
      ) {
        const validationNumber = findIndex(res.errors[0].title);
        return I18n.t(T.customerSingle.batteryDetails.timeOfUse.notifications.powerFromGridFailed, {
          value: validationNumber,
        });
      }
      return I18n.t(T.customerSingle.batteryDetails.timeOfUse.notifications.failed, {
        name: I18n.t(T.customerSingle.batteryDetails.timeOfUse.offPeak),
      });
    })
  );
};
