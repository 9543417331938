import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';

import { isNil } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';

import { setNumbersHistory } from '+app/+customer/+overview/components/CustomerBatteryWidget/CustomerBatteryWidget.helper';
import { EnergyUnit } from '+app/App.constants';
import { goTo } from '+app/utils';
import { DetailListType } from '+shared/components';
import { Button, ButtonType } from '+shared/components/Button';
import {
  Battery,
  BatteryElectricUnits,
  BatteryType,
  BatteryWithProduct,
} from '+shared/store/battery';
import { getBatteryProductName } from '+shared/store/battery/battery.helpers';
import { formatEnergy, formatPower } from '+utils/format.util.old';
import { trim } from '+utils/string.util';

const getBatteryModuleName = (battery: BatteryWithProduct): string | null => {
  const isEatonBattery = battery.controllerType === BatteryType.EATON;

  return isEatonBattery ? battery.product.cellTechnology : battery.batteryModuleTypeMarketing;
};

const factorizeSerialAndProductNumber = (
  serialNumber: string,
  productCode: string | null | undefined
): string => `${serialNumber || '-'} / ${productCode || '-'}`;

const isReadyForReleaseNote = ({ installationCountryCode, controllerType }: Battery): boolean =>
  installationCountryCode === CountryCode.DE && controllerType === BatteryType.SPREE;

export const factorizeBatteryTechSpecsDetailList = (
  battery: BatteryWithProduct,
  batteryElectricUnits: BatteryElectricUnits[]
): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.model),
    value: getBatteryProductName(battery.product),
  },
  {
    label: trim`
      ${I18n.t(T.customerSingle.batteryDetails.techSpecs.serialNumber)}
       /
      ${I18n.t(T.customerSingle.batteryDetails.techSpecs.articleNumber)}
    `,
    value: factorizeSerialAndProductNumber(battery.serialNumber, battery.product?.productCode),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.softwareVersion),
    value: (
      <div className={'c-battery-live-card__software'}>
        {!isNil(battery.softwareVersion) ? battery.softwareVersion : '-'}
        {isReadyForReleaseNote(battery) && (
          <>
            <br />
            <Button
              type={ButtonType.TERTIARY}
              label={
                <>
                  {I18n.t(T.batterySystem.details.checkReleaseNote.title)}
                  <Icon.Angle className={'c-battery-live-card__link-arrow'} />
                </>
              }
              onClick={() => goTo(I18n.t(T.batterySystem.details.checkReleaseNote.link), '_blank')}
            />
          </>
        )}
      </div>
    ),
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.oldSerialNumbers),
    value: setNumbersHistory(batteryElectricUnits),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.capacity),
    value:
      !isNil(battery.batteryCapacity) &&
      formatEnergy({
        whValue: battery.batteryCapacity,
        unit: EnergyUnit.KWH,
        precision: 1,
        minPrecision: 0,
      }),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.maxOutputPower),
    value:
      !isNil(battery.batteryInverterDesignPower) &&
      formatPower(battery.batteryInverterDesignPower, EnergyUnit.KW, 1),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.numberOfModules),
    value: battery.batteryModules,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.display.headline),
    value: isNil(battery.product?.batteryWithDisplay)
      ? undefined
      : battery.product?.batteryWithDisplay
      ? I18n.t(T.customerSingle.batteryDetails.techSpecs.display.true)
      : I18n.t(T.customerSingle.batteryDetails.techSpecs.display.false),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.batteryTechnology),
    value: getBatteryModuleName(battery),
  },
];
