import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';

import { Form, Formik, FormikProps } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { defaultTo, noop } from 'lodash/fp';
import { CountryCode } from 'src/types/country.type';
import uuid from 'uuid';

import { CountryFeatureName } from '+config/countryFlags';
import { KNOWLEDGE_BASE } from '+config/env.config';
import {
  BatteryOperatingModeConfirmModal,
  BatteryOperatingModeForm,
} from '+customer-battery/components';
import { FormInputRadioGroup, HorizontalPositionProps, TooltipHoverable } from '+shared/components';
import { Button, ButtonSize, ButtonStatus, ButtonType, MainType } from '+shared/components/Button';
import { BatteryOperatingMode, BatteryOperatingModeOptions } from '+shared/store/site/types';
import { goTo } from '+utils/browser.util';

import { BatteryOperatingModeView } from '../../containers/BatteryOperatingModeOperation/BatteryOperatingModeOperation.const';
import { defaultValues } from './BatteryOperatingModeOperationsForm.const';
import {
  getAvailableOperatingModes,
  getOperatingModeDisabledMessage,
  isSoftwareVersionOlderThanAllowedVersion,
} from './BatteryOperatingModeOperationsForm.helpers';
import {
  ALLOWED_SOFTWARE_VERSION,
  BatteryOperatingModeFormKeys,
} from './BatteryOperatingModeOperationsForm.types';

import './BatteryOperatingModeOperationsForm.component.scss';

interface BatteryOperatingModeOperationsFormProps {
  operatingMode: BatteryOperatingMode | undefined;
  userCountry: CountryCode;
  isBatteryVppActive: boolean;
  batterySoftwareVersion: string | undefined;
  onCancel: (form: FormikProps<BatteryOperatingModeForm>) => () => void;
  onSubmit: (form: FormikProps<BatteryOperatingModeForm>) => () => void;
  setIsWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBatteryOperatingModeView: React.Dispatch<React.SetStateAction<BatteryOperatingModeView>>;
}

const {
  changeOperatingMode: { info, linkText },
  intelligentChargingManagement: { buttonCancel, buttonNext },
} = T.customerSingle.batteryDetails.batteryOperations;
const { editTariffWindows } = T.customerSingle.batteryDetails.timeOfUse.action;
export const BatteryOperatingModeOperationsForm: React.FC<
  BatteryOperatingModeOperationsFormProps
> = ({
  operatingMode,
  userCountry,
  isBatteryVppActive,
  batterySoftwareVersion,
  onCancel,
  onSubmit,
  setIsWidgetOpen,
  setBatteryOperatingModeView,
}) => {
  const { isEnabled: isOperatingModeLinkEnabled } = useCountryFeature(
    CountryFeatureName.OPERATING_MODE_LINK
  );

  const initialValues = defaultTo(defaultValues)({ operatingMode });

  const { timeOfUseFlag } = useFlags();
  const isSoftwareVersionTooOld = isSoftwareVersionOlderThanAllowedVersion(
    batterySoftwareVersion,
    ALLOWED_SOFTWARE_VERSION
  );

  return (
    <Formik initialValues={initialValues} onSubmit={noop}>
      {(form) => (
        <>
          <Form>
            <div className="battery-operating-mode-operation__container">
              <FormInputRadioGroup<Pick<BatteryOperatingModeForm, 'operatingMode'>>
                className="battery-operating-mode-operation__radio-group"
                name={BatteryOperatingModeFormKeys.OPERATING_MODE}
                form={form}
                collection={getAvailableOperatingModes(isBatteryVppActive, timeOfUseFlag)}
              />
              <div className="battery-operating-mode-operation__info">
                {I18n.t(info)}{' '}
                {isOperatingModeLinkEnabled ? (
                  <Button
                    label={I18n.t(linkText)}
                    className="battery-operating-mode-operation__link"
                    type={ButtonType.TERTIARY}
                    onClick={() => goTo(KNOWLEDGE_BASE.batteryOperatingModeHelp)}
                  />
                ) : (
                  <span>{I18n.t(linkText)}</span>
                )}
              </div>
            </div>
            <div className="battery-operating-mode-operation__footer">
              <Button
                label={I18n.t(buttonCancel)}
                type={ButtonType.TERTIARY}
                size={ButtonSize.SMALL}
                onClick={() => setIsWidgetOpen((isWidgetOpen) => !isWidgetOpen)}
              />
              {operatingMode === BatteryOperatingModeOptions.US_TIME_OF_USE &&
              form.values.operatingMode === BatteryOperatingModeOptions.US_TIME_OF_USE ? (
                <TooltipHoverable
                  key={uuid()}
                  title={getOperatingModeDisabledMessage(isBatteryVppActive)}
                  isHoveringDisabled={!isSoftwareVersionTooOld}
                  bubbleSide={'bottom-right'}
                  horizontalPosition={HorizontalPositionProps.LEFT}
                  topHeight={90}
                >
                  <Button
                    label={I18n.t(editTariffWindows)}
                    size={ButtonSize.SMALL}
                    disabled={isSoftwareVersionTooOld || isBatteryVppActive}
                    status={
                      isSoftwareVersionTooOld || isBatteryVppActive
                        ? ButtonStatus.DISABLED
                        : ButtonStatus.ENABLED
                    }
                    onClick={() =>
                      setBatteryOperatingModeView(BatteryOperatingModeView.TIME_OF_USE_PREVIEW)
                    }
                  />
                </TooltipHoverable>
              ) : (
                <Button
                  label={I18n.t(buttonNext)}
                  mainType={MainType.SUBMIT}
                  size={ButtonSize.SMALL}
                  disabled={form.values.operatingMode === operatingMode}
                  status={
                    form.values.operatingMode === operatingMode
                      ? ButtonStatus.DISABLED
                      : ButtonStatus.ENABLED
                  }
                />
              )}
            </div>
          </Form>

          {form.isSubmitting && (
            <BatteryOperatingModeConfirmModal
              isOpen={form.isSubmitting}
              newOperatingMode={form.values.operatingMode}
              onCancel={onCancel(form)}
              onConfirm={onSubmit(form)}
              userCountry={userCountry}
            />
          )}
        </>
      )}
    </Formik>
  );
};
