import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Modal } from '@sonnen/shared-web';

import { mapActions } from '+app/utils';
import { Button, ButtonType } from '+shared/components/Button';
import { CaseReportSearchSite } from '+shared/components/CaseReportSearchSite';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { CaseReportHelper } from '+shared/containers';
import {
  CaseActions,
  getCaseActiveSite,
  getCaseSubmitQueryStatus,
  getCreatedCaseNumber,
} from '+shared/store/case';
import { ContractActions } from '+shared/store/contract/contract.actions';
import { CustomerActions } from '+shared/store/customer';
import {
  getCustomerList,
  getCustomerListQueryStatus,
} from '+shared/store/customer/customer.selectors';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserCountryCode,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';

import {
  CaseReportError,
  CaseReportForm,
  CaseReportSite,
  CaseReportSuccess,
} from '../../components';

import './CaseReport.component.scss';

const mapStateToProps = (state: StoreState) => ({
  activeSite: getCaseActiveSite(state),
  userCountry: getUserCountryCode(state),
  caseSubmitQueryStatus: getCaseSubmitQueryStatus(state),
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  partnerContactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
  customerList: getCustomerList(state),
  customerListQueryStatus: getCustomerListQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getContract: ContractActions.getContract,
  createCase: CaseActions.createCase,
  resetCaseSubmit: CaseActions.clearCreatedCase,
  toggleModal: LayoutActions.toggleModal,
  setActiveSite: CaseActions.setCaseReportActiveSite,
  getCustomerList: CustomerActions.getCustomerList,
});

interface ComponentProps {
  canEditSite?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ComponentProps;

const CaseReportComponent: React.FC<Props> = React.memo(
  ({
    actions,
    activeSite,
    userCountry,
    caseSubmitQueryStatus,
    openModalId,
    partnerContactId,
    caseNumber,
    customerList,
    customerListQueryStatus,
    canEditSite,
  }) => {
    const [formState, setFormState] = React.useState<FormState>(FormState.FORM);
    const siteList = React.useMemo(
      () => CaseReportHelper.getCaseSiteList(customerList),
      [customerList]
    );

    React.useEffect(() => {
      if (caseSubmitQueryStatus.success) {
        setFormState(FormState.FORM_SUCCESS);
      }

      if (caseSubmitQueryStatus.error) {
        setFormState(FormState.FORM_ERROR);
      }

      if (
        !caseSubmitQueryStatus.error &&
        !caseSubmitQueryStatus.success &&
        !caseSubmitQueryStatus.pending &&
        caseSubmitQueryStatus.notReady
      ) {
        setFormState(FormState.FORM);
      }
    }, [caseSubmitQueryStatus]);

    const onClose = () => {
      actions.resetCaseSubmit();
      setFormState(FormState.FORM);
      actions.toggleModal(false);
      actions.setActiveSite();
    };

    const onMore = () => {
      actions.resetCaseSubmit();
      setFormState(FormState.FORM);
    };

    const onTryAgain = () => {
      actions.resetCaseSubmit();
      setFormState(FormState.FORM);
    };

    return (
      <Modal
        isOpen={openModalId === ModalId.CASE_REPORT}
        onClose={onClose}
        dataTestId="report-case-modal"
      >
        {
          {
            [FormState.FORM]: (
              <CaseReportForm
                activeSite={activeSite}
                isLoading={caseSubmitQueryStatus.pending}
                userCountry={userCountry}
                submitCaseForm={actions.createCase}
                partnerContactId={partnerContactId}
                siteComponent={
                  activeSite ? (
                    <CaseReportSite
                      address={activeSite.address}
                      name={activeSite.name}
                      batterySerialNumber={activeSite.battery?.serialNumber}
                      contractNumber={activeSite.contract?.contractNumber}
                      button={
                        canEditSite ? (
                          <Button
                            label={I18n.t(T.report.site.editButton.label)}
                            onClick={() => actions.setActiveSite()}
                            type={ButtonType.TERTIARY}
                            className={'caseReport__action'}
                          />
                        ) : undefined
                      }
                    />
                  ) : (
                    <CaseReportSearchSite
                      getCustomerList={actions.getCustomerList}
                      siteList={siteList}
                      customerListQueryStatus={customerListQueryStatus}
                      setActiveSite={actions.setActiveSite}
                      activeSite={activeSite}
                    />
                  )
                }
              />
            ),
            [FormState.FORM_ERROR]: <CaseReportError onTryAgain={onTryAgain} />,
            [FormState.FORM_SUCCESS]: (
              <CaseReportSuccess caseNumber={caseNumber} onClose={onClose} onMore={onMore} />
            ),
          }[formState]
        }
      </Modal>
    );
  }
);

export const CaseReport = connect(mapStateToProps, mapDispatchToProps)(CaseReportComponent);
