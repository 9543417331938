import { Contract, FstCheckResults } from './types/contract.interface';

export enum ContractType {
  SONNEN_FLAT_IT = 'sonnenFlatIt',
  SONNEN_FLAT = 'sonnenFlat',
  SONNEN_STROM = 'sonnenStrom',
  ENERGY = 'energy',
  ENERGY_GLOBAL = 'energyGlobal',
  ATHENA_ENERGY = 'athenaEnergy',
  /* Following types are not used in the UI, but mapping here as documentation */
  SONNEN_CONNECT_AU = 'sonnenConnectAu',
  SONNEN_FLAT_AU = 'sonnenFlatAu',
  CONTROL = 'control',
}

export enum ContractName {
  ENERGY_DYNAMIC = 'EnergyDynamic',
  SONNEN_CONNECT = 'sonnenConnect',
  SONNEN_FLAT = 'sonnenFlat',
  SONNEN_FLAT_HOME = 'sonnenFlat Home',
  SONNEN_FLAT_DIRECT = 'sonnenFlat direkt',
  SONNEN_FLAT_X = 'sonnenFlat',
  SONNEN_STROM = 'SonnenStrom', // in UI as sonnenStrom
  SONNEN_STROM_2 = 'sonnenStrom NEU', // in UI as sonnenStrom
  SONNEN_FLAT_1500 = 'sonnenFlat 1500',
  SONNEN_ENERGIA_1 = 'sonnenEnergia 1.0',
  SONNEN_ENERGIA_IT = 'sonnenEnergia',
}

export const contractNameByType: Record<ContractType, ContractName[]> = {
  [ContractType.SONNEN_FLAT_IT]: [ContractName.SONNEN_ENERGIA_IT, ContractName.SONNEN_FLAT_1500],
  [ContractType.SONNEN_FLAT]: [ContractName.SONNEN_FLAT],
  [ContractType.SONNEN_FLAT_AU]: [ContractName.SONNEN_FLAT],
  [ContractType.SONNEN_STROM]: [ContractName.SONNEN_STROM],
  [ContractType.ENERGY]: [ContractName.SONNEN_ENERGIA_1],
  [ContractType.ENERGY_GLOBAL]: [ContractName.SONNEN_FLAT_DIRECT, ContractName.SONNEN_FLAT_X],
  [ContractType.ATHENA_ENERGY]: [ContractName.ENERGY_DYNAMIC, ContractName.SONNEN_STROM_2],
  [ContractType.SONNEN_CONNECT_AU]: [ContractName.SONNEN_CONNECT],
  [ContractType.CONTROL]: [ContractName.SONNEN_CONNECT],
};

export enum TariffType {
  START = 'start',
  SOLAR = 'solar',
  PLUS = 'plus',
  SONNENBATTERIE_HOME_AT = 'sonnenbatterieHome-AT',
  SONNENBATTERIE_HOME_DE = 'sonnenbatterieHome-DE',
  SONNEN_ENERGY_IT = 'sonnenEnergy-IT',
  SONNEN_FLAT_1500_IT = 'sonnenFlat1500-IT',
}

export const isFSTCheckFalse = (
  meterFstCheckSuccessful: FstCheckResults | null | undefined
): boolean =>
  meterFstCheckSuccessful === FstCheckResults.NO ||
  meterFstCheckSuccessful === null ||
  meterFstCheckSuccessful === undefined;

export const isFSTCheckNotRequired = (
  meterFstCheckSuccessful: FstCheckResults | null | undefined
): boolean => meterFstCheckSuccessful === FstCheckResults.NOT_REQUIRED;

export const isContractSonnenFlat = (contract: Contract) =>
  contract.contractType === ContractType.SONNEN_FLAT;

export const isContractSonnenFlatX = (contract: Contract) =>
  contract.contractType === ContractType.ENERGY_GLOBAL;

/* The DT check is based on the contract name instead of type,
 * because athenaEnergy type can have both sonnenStrom NEU and energyDynamic names.
 */
export const isDynamicTariffOrSonnenStrom = (contract: Contract) =>
  [ContractName.ENERGY_DYNAMIC, ContractName.SONNEN_STROM, ContractName.SONNEN_STROM_2].includes(
    contract.name as ContractName
  );

export const formatContractName = (contractName: string) =>
  contractName === ContractName.SONNEN_STROM_2 || contractName === ContractName.SONNEN_STROM
    ? 'sonnenStrom'
    : contractName;
