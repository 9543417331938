import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';

import { compareVersions } from 'compare-versions';
import { isNil } from 'lodash';

import { CountryFeatureName } from '+config/index';
import { RadioButton } from '+shared/components/Form/FormInputRadioGroup/FormInputRadioGroup.component';
import { BatteryOperatingModeOptions } from '+shared/store/site/types';
import { insertIf } from '+utils/array.util';

export const isSoftwareVersionOlderThanAllowedVersion = (
  batterySoftwareVersion: string | undefined | null,
  allowedSoftwareVersion: string
): boolean => {
  if (isNil(batterySoftwareVersion)) return false;

  return compareVersions(batterySoftwareVersion, allowedSoftwareVersion) === -1;
};

const { selfConsumption, moduleExtension, timeOfUse, optimization } =
  T.customerSingle.batteryDetails.batteryLive.operatingMode;

const {
  selfConsumptionDescription,
  moduleExtensionDescription,
  timeOfUseDescription,
  timeOfUseDisabledVppMessage,
  timeOfUseDisabledSoftwareMessage,
  optimizationDescription,
} = T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode;

export const getOperatingModeDisabledMessage = (isBatteryVppActive: boolean): string =>
  I18n.t(isBatteryVppActive ? timeOfUseDisabledVppMessage : timeOfUseDisabledSoftwareMessage);

export const featureEnabled = (feature: CountryFeatureName): boolean => {
  const { isEnabled } = useCountryFeature(feature);
  return isEnabled || false;
};

export const getAvailableOperatingModes = (
  batteryVppActive: boolean,
  timeOfUseEnabled: boolean
): RadioButton[] => {
  const timeOfUseAvailable = featureEnabled(CountryFeatureName.SET_TIME_OF_USE_MODE);
  const optimizationModeAvailable = featureEnabled(CountryFeatureName.SET_OPTIMIZATION_MODE);
  const moduleExtensionModeAvailable = featureEnabled(CountryFeatureName.SET_MODULE_EXTENSION_MODE);

  return [
    {
      label: I18n.t(selfConsumption),
      value: BatteryOperatingModeOptions.SELF_CONSUMPTION,
      text: I18n.t(selfConsumptionDescription),
    },
    ...insertIf(timeOfUseAvailable && timeOfUseEnabled, {
      label: I18n.t(timeOfUse),
      value: BatteryOperatingModeOptions.US_TIME_OF_USE,
      text: I18n.t(timeOfUseDescription),
      selectionDisabled: batteryVppActive,
      disabledMessage: I18n.t(timeOfUseDisabledVppMessage),
    }),
    ...insertIf(optimizationModeAvailable, {
      label: I18n.t(optimization),
      value: BatteryOperatingModeOptions.OPTIMIZATION,
      text: I18n.t(optimizationDescription),
    }),
    ...insertIf(moduleExtensionModeAvailable, {
      label: I18n.t(moduleExtension),
      value: BatteryOperatingModeOptions.MODULE_EXTENSION,
      text: I18n.t(moduleExtensionDescription),
    }),
  ];
};
