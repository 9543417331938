import * as React from 'react';

import { Icofont } from '@sonnen/shared-web';

import { DetailList } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { useLocaleContext } from '+utils/react/locale.provider';

import { factorizeBackupBoxDetailList, generateBoxHeader } from './BackupBoxCard.helper';
import { BackupBoxCard as BackupBoxCardInterface } from './types/BackupBoxCard.types';

interface Props {
  backupBox: BackupBoxCardInterface;
}

export const BackupBoxCard: React.FC<Props> = ({ backupBox }) => {
  const { locale } = useLocaleContext();

  return (
    <Widget
      icon={<Icofont type={'backup-box'} />}
      heading={generateBoxHeader(backupBox.deviceType)}
    >
      <DetailList list={factorizeBackupBoxDetailList(locale)(backupBox)} filterNil={true} />
    </Widget>
  );
};
