import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { PartnerSite } from '+app/+customer/+listNew/store/types/partnerSite.interface';
import { EnergyUnit } from '+app/App.constants';
import { PlusWithCircle } from '+shared/basicComponents/Icons';
import Checkbox from '+shared/components/Checkbox/Checkbox';
import TruncableText from '+shared/components/TruncableText/TruncableText';
import { CaseReportHelper } from '+shared/containers';
import { CaseActions } from '+shared/store/case';
import { formatContractName } from '+shared/store/contract/contract.helpers';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { formatDate, formatEnergy } from '+utils/format.util';
import { useLocaleContext } from '+utils/react/locale.provider';

import { PartnerSitesBattery } from '../../../../store/types/partnerSitesBattery.interface';
import { highlightMatchingSubstring } from './SitesTableDataCell.helper';

import './SitesTableDataCells.scss';

export const SitesListSelection: React.FC<{}> = () => (
  <div className="sites-list__selection">
    <Checkbox id={''} name={''} isChecked={false} />
  </div>
);

export const SitesListCustomerName: React.FC<{ name: string; searchValue: string | null }> = ({
  name,
  searchValue,
}) => {
  // this check is necessary otherwise name and serial number will be highlighted at the same time
  const isSearchValueNameOrNotSetAsState = isNaN(Number(searchValue)) ? searchValue : null;
  const displayedContent = isSearchValueNameOrNotSetAsState
    ? highlightMatchingSubstring(
        isSearchValueNameOrNotSetAsState,
        name,
        'sites-list__search-bold-text'
      )
    : name;

  return (
    <TruncableText
      displayedContent={displayedContent}
      displayedContentClassName="sites-list__name"
      tooltipContentClassName="truncable-text-tooltip-content"
      // DO NOT place tooltip bottom for CustomerName cell.
      // Because of the "position: sticky" that each cell in the name column has,
      // a new stacking context is created and tooltip is always hidden
      // under the CustomerName of the following line.
      tooltipPosition="top"
    />
  );
};

export const SitesListSerialNumber: React.FC<{
  serial: string;
  searchValue: string | null;
}> = ({ serial, searchValue }) => {
  // this check is necessary otherwise name and serial number will be highlighted at the same time
  const isSearchValueSerialNumberOrNotSetAsState = !isNaN(Number(searchValue)) ? searchValue : null;
  const displayedContent = isSearchValueSerialNumberOrNotSetAsState
    ? highlightMatchingSubstring(
        isSearchValueSerialNumberOrNotSetAsState,
        serial,
        'sites-list__search-bold-text'
      )
    : serial;

  return (
    <TruncableText
      displayedContent={displayedContent}
      displayedContentClassName="sites-list__serial"
    />
  );
};

export const SitesListBattery: React.FC<{ battery: PartnerSitesBattery }> = ({ battery }) => {
  const { locale } = useLocaleContext();

  let displayName: string =
    battery.product?.quoteNameDe ?? I18n.t(T.customerSingle.batteryDetails.defaultBattery);
  if (battery.capacity) {
    displayName += ` (${formatEnergy({
      locale,
      unit: EnergyUnit.KWH,
      precision: 1,
      minPrecision: 0,
    })(battery.capacity)})`;
  }

  return (
    <TruncableText displayedContent={displayName} displayedContentClassName="sites-list__battery" />
  );
};

export const SitesListLocation: React.FC<{ location: string }> = ({ location }) => {
  const locationOnTwoLines = displayLocationOnTwoLines(location);
  const tooltipPaddingForTwoLines =
    typeof locationOnTwoLines === 'string' ? undefined : 'two-lines-padding';

  return (
    <TruncableText
      displayedContent={location}
      tooltipContent={locationOnTwoLines}
      displayedContentClassName="sites-list__location"
      tooltipContentClassName={`truncable-text-tooltip-content ${tooltipPaddingForTwoLines}`}
    />
  );
};

export const SitesListCommissioningDate: React.FC<{ commissioningDate: string | undefined }> = ({
  commissioningDate,
}) => (
  <TruncableText
    displayedContent={commissioningDate ? formatDate(commissioningDate) : '—'}
    displayedContentClassName="sites-list__commissioning"
  />
);

export const SitesListContract: React.FC<{ site: PartnerSite }> = ({ site }) => {
  const nameOfPrimaryContract = site.contracts?.[0]?.name;

  if (!nameOfPrimaryContract) return <>{'—'}</>;
  const formattedName = formatContractName(nameOfPrimaryContract);

  return (
    <TruncableText
      displayedContent={formattedName}
      displayedContentClassName="sites-list__contract"
    />
  );
};

type SiteCases = {
  site: PartnerSite;
  toggleModal: typeof LayoutActions.toggleModal;
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite;
};
export const SitesListCases: React.FC<SiteCases> = ({
  site,
  toggleModal,
  setCaseReportActiveSite,
}) => {
  if (site.locked) {
    return <></>;
  }

  const handleCaseReportButton = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const activeSite = CaseReportHelper.transformPartnerSite(site);
    toggleModal(true, ModalId.CASE_REPORT);
    setCaseReportActiveSite(activeSite);
  };
  return (
    <div className="sites-list__cases">
      <button onClick={handleCaseReportButton} data-testid="add-case-button">
        <PlusWithCircle className="create-new-case" />
      </button>
    </div>
  );
};

/*
 * Helpers
 */
const displayLocationOnTwoLines = (
  displayedContent: string | JSX.Element
): string | JSX.Element => {
  return typeof displayedContent !== 'string' ? (
    displayedContent
  ) : (
    <ul>
      {displayedContent.split(',').map((line) => (
        <li key={line}>{line}</li>
      ))}
    </ul>
  );
};
