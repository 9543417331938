import React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';

import classNames from 'classnames';

import { DetailList, WidgetWarning } from '+shared/components';
import { Widget } from '+shared/components/Widget/Widget';
import { formatContractName } from '+shared/store/contract/contract.helpers';
import {
  Contract,
  MeterInstallationAttributes,
} from '+shared/store/contract/types/contract.interface';
import { getStatus } from '+shared/store/query/query.utils';

import { factorizeContractDetails } from './CustomerContractWidget.helper';

interface Props {
  contract: Contract | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  className?: ClassValue;
  meterInstallation?: MeterInstallationAttributes;
  link: () => void;
}

export const CustomerContractWidget: React.FC<Props> = ({
  link,
  contract,
  queryStatus,
  className,
  meterInstallation,
}) => {
  if (!contract) return null;
  const list = factorizeContractDetails({ contract, meterInstallation });

  return (
    <Widget
      className={classNames('c-customer-overview__tile', className)}
      heading={formatContractName(contract.name)}
      icon="Contract"
      link={link}
    >
      {queryStatus.pending && <Loader />}

      {queryStatus.success && <DetailList list={list} />}

      {queryStatus.error && (
        <WidgetWarning type="warning" icon="warning">
          {I18n.t(T.customerSingle.overview.sonnenFlat.error)}
        </WidgetWarning>
      )}
    </Widget>
  );
};
