import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash/fp';

import { ContractType } from '+shared/store/contract/contract.helpers';
import { Contract } from '+shared/store/contract/types/contract.interface';

export const getMaxEnergyAmount = (amountOfFreeEnergy: number | null) =>
  isNil(amountOfFreeEnergy) ? 0 : amountOfFreeEnergy;

export const getFormattedContractType = (contract: Contract): string => {
  const contractType: string = contract.contractType ?? contract.name ?? '';
  const key =
    contract.tariffVersion === 'direkt 2.0' || contract.tariffVersion === 'direkt'
      ? 'sonnenFlatDirect'
      : 'sonnenFlatX';

  switch (contractType.toLowerCase()) {
    case ContractType.ENERGY_GLOBAL.toLowerCase():
      return I18n.t(T.customerSingle.overview.contractHeader[key]);

    case ContractType.SONNEN_FLAT_IT.toLowerCase():
      return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlatIt);

    case ContractType.SONNEN_FLAT.toLowerCase():
      return I18n.t(T.customerSingle.overview.contractHeader.sonnenFlat);

    case ContractType.SONNEN_STROM.toLowerCase():
      return I18n.t(T.customerSingle.overview.contractHeader.sonnenStrom);

    case ContractType.ENERGY.toLowerCase():
      return I18n.t(T.customerSingle.overview.contractHeader.sonnenEnergy);

    default:
      return '—';
  }
};
