import React from 'react';
import * as Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { useCountryFeature } from '@sonnen/shared-web';

import { push } from 'connected-react-router';
import { compose } from 'lodash/fp';

import {
  CustomerAnalysisWidget,
  CustomerBatteryWidget,
  CustomerCasesWidget,
  CustomerChargerWidget,
  CustomerDetailsWidget,
  CustomerNoteWidget,
  CustomerPvSystemWidget,
} from '+app/+customer/+overview/components';
import { CustomerWarnings } from '+app/+customer/components';
import {
  getAssetsAvailability,
  getMeterInstallation,
  getSite,
} from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { CustomerRouteParams, PATHS } from '+app/router';
import {
  getSiteConsumedEnergyFormatted,
  getSiteGridPurchasedEnergyFormatted,
} from '+app/shared/store/site/site.selectors';
import { mapActions } from '+app/utils';
import { CountryFeatureName } from '+config/countryFlags';
import {
  getBattery,
  getBatteryElectricUnits,
  getBatteryElectricUnitsQueryStatus,
  getBatteryQueryStatus,
} from '+customer-battery/store/+battery.selectors';
import { getContract, getContractQueryStatus } from '+customer-contract/store/+contract.selectors';
import {
  getCharger,
  getChargersQueryStatus,
  getHeatPumpLiveState,
  getOverviewSiteLiveState,
  getOverviewSiteLiveStateQueryStatus,
  hasSiteHeatpump,
} from '+customer-overview/store/+overview.selector';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { getCaseCollection } from '+shared/store/case';
import {
  getBatteryWarnings,
  getContractWarnings,
  getOnlyDangerWarnings,
  getWarningMessages,
} from '+shared/store/customer/warnings/warnings.factory';
import { getCombinedStatus } from '+shared/store/query/query.utils';
import { getSiteLiveState, getSiteLiveStateQueryStatus } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';

import { CustomerContractWidget } from '../../components/CustomerContractWidget/CustomerContractWidget.component';
import { CustomerHeatPumpWidget } from '../../components/CustomerHeatPumpWidget/CustomerHeatPumpWidget';

import './CustomerOverview.component.scss';

type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const meterInstallation = getMeterInstallation(site);
  const cases = getCaseCollection(state);
  const assetsAvailability = getAssetsAvailability(site, state);
  const battery = getBattery(state);
  const siteLiveState = getSiteLiveState(state);
  const overviewLiveState = getOverviewSiteLiveState(state);
  const overviewLiveStateQuery = getOverviewSiteLiveStateQueryStatus(state);
  const batteryElectricUnits = getBatteryElectricUnits(state);
  const batteryQueryStatus = getCombinedStatus([
    getBatteryQueryStatus(state),
    getSiteLiveStateQueryStatus(state),
    getBatteryElectricUnitsQueryStatus(state),
  ]);
  const siteLiveStateQueryStatus = getSiteLiveStateQueryStatus(state);
  const contract = getContract(state);
  const contractQueryStatus = getContractQueryStatus(state);
  const charger = getCharger(state);
  const chargersQueryStatus = getChargersQueryStatus(state);
  const consumedEnergy = getSiteConsumedEnergyFormatted(state);
  const gridPurchaseEnergy = getSiteGridPurchasedEnergyFormatted(state);
  const hasHeatpump = hasSiteHeatpump(state);
  const heatpump = getHeatPumpLiveState(state);

  return {
    assetsAvailability,
    contract,
    contractQueryStatus,
    customer,
    site,
    battery,
    siteLiveState,
    overviewLiveState,
    overviewLiveStateQuery,
    batteryQueryStatus,
    siteLiveStateQueryStatus,
    cases,
    charger,
    chargersQueryStatus,
    consumedEnergy,
    gridPurchaseEnergy,
    batteryElectricUnits,
    meterInstallation,
    hasHeatpump,
    heatpump,
  };
};

type Location = 'battery' | 'analysis' | 'contract' | 'cases' | 'heatpump' | 'pvSystems';

const mapDispatchToProps = mapActions({
  goTo: (params: CustomerRouteParams, location: Location) =>
    ({
      battery: push(PATHS.CUSTOMER_BATTERY(params)),
      heatpump: push(PATHS.CUSTOMER_HEAT_PUMP(params)),
      pvSystems: push(PATHS.CUSTOMER_PV_SYSTEMS(params)),
      analysis: push(PATHS.CUSTOMER_ANALYSIS(params)),
      contract: push(PATHS.CUSTOMER_CONTRACT(params)),
      cases: push(PATHS.CUSTOMER_CASES(params)),
    }[location]),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteProps;

const CustomerOverviewComponent: React.FC<Props> = ({
  battery,
  siteLiveState,
  overviewLiveState,
  overviewLiveStateQuery,
  batteryElectricUnits,
  batteryQueryStatus,
  siteLiveStateQueryStatus,
  cases,
  contract,
  contractQueryStatus,
  customer,
  site,
  assetsAvailability,
  charger,
  chargersQueryStatus,
  meterInstallation,
  actions,
  heatpump,
  hasHeatpump,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.DETAILS);

  const isCustomerContractFeatureEnabled = useCountryFeature(
    CountryFeatureName.CUSTOMER_CONTRACT
  ).isEnabled;

  const getWidgets = () => {
    const routeParams: CustomerRouteParams = {
      customerId: customer ? customer.id : '',
      siteId: site ? site.id : '',
    };

    return {
      details: customer && site && (
        <CustomerDetailsWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-details'}
          customer={customer}
          site={site}
        />
      ),
      battery: assetsAvailability.hasBattery && (
        <CustomerBatteryWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-battery'}
          battery={battery}
          siteLiveState={siteLiveState!}
          batteryElectricUnits={batteryElectricUnits}
          queryStatus={batteryQueryStatus}
          action={() => actions.goTo(routeParams, 'battery')}
          link={() => actions.goTo(routeParams, 'battery')}
        />
      ),
      heatPump: customer && site && hasHeatpump && (
        <CustomerHeatPumpWidget
          className="c-customer-overview__grid-item"
          key="customer-heat-pump"
          heatpump={heatpump}
          link={() => actions.goTo(routeParams, 'heatpump')}
        />
      ),
      analysis: (!!overviewLiveState || !!siteLiveState) && (
        <CustomerAnalysisWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-analysis'}
          pollingSiteLiveState={overviewLiveState}
          initialSiteLiveState={siteLiveState}
          initialQueryStatus={siteLiveStateQueryStatus}
          pollingQueryStatus={overviewLiveStateQuery}
          action={() => actions.goTo(routeParams, 'analysis')}
          link={() => actions.goTo(routeParams, 'analysis')}
        />
      ),
      pvSystem: assetsAvailability.hasPvSystem && (
        <CustomerPvSystemWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-pv-system'}
          battery={battery}
          queryStatus={batteryQueryStatus}
          link={() => actions.goTo(routeParams, 'pvSystems')}
        />
      ),
      contract: assetsAvailability.hasContract && isCustomerContractFeatureEnabled && (
        <CustomerContractWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-contract'}
          contract={contract}
          meterInstallation={meterInstallation}
          queryStatus={contractQueryStatus}
          link={() => actions.goTo(routeParams, 'contract')}
        />
      ),
      cases: !!cases.length && (
        <CustomerCasesWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-cases'}
          cases={cases}
          link={() => actions.goTo(routeParams, 'cases')}
        />
      ),
      charger: assetsAvailability.hasCharger && charger && (
        <CustomerChargerWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-charger'}
          charger={charger}
          queryStatus={chargersQueryStatus}
        />
      ),
      note: customer && site && (
        <div className={'c-customer-overview__grid-item'} key={'customer-note'}>
          <CustomerNoteWidget
            customerId={customer.id}
            customerNote={customer.installerNote || ''}
          />
        </div>
      ),
    };
  };

  const batteryWarnings = compose(
    getWarningMessages,
    getBatteryWarnings
  )({ battery, siteLiveState });

  const contractWarnings = compose(
    getOnlyDangerWarnings,
    getWarningMessages,
    getContractWarnings
  )({ contract, meterInstallation });

  const widgets = getWidgets();
  const orderedWidgets = [
    widgets.details,
    widgets.battery,
    widgets.heatPump,
    widgets.contract,
    widgets.cases,
    widgets.charger,
    widgets.pvSystem,
    widgets.analysis,
    widgets.note,
  ].sort();

  const warnings = [...batteryWarnings, ...contractWarnings];

  return (
    <>
      <CustomerWarnings warnings={warnings} />
      <Masonry
        className={'c-customer-overview__grid'}
        elementType={'div'}
        options={{
          percentPosition: true,
          columnWidth: '.c-customer-overview__grid-item ',
          transitionDuration: 0,
        }}
      >
        <div className={'c-customer-overview__grid-item '} />
        {orderedWidgets}
      </Masonry>
    </>
  );
};

export const CustomerOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerOverviewComponent);
