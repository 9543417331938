import React from 'react';

import { Table, TableCell, TableRow, TableStack } from '@sonnen/shared-web';

import classNames from 'classnames';
import { isNil } from 'lodash';

import { BodyMedium } from '+shared/basicComponents/Typography/Bodies';
import { LabelMedium } from '+shared/basicComponents/Typography/Labels';

import { DetailListType } from '../DetailListTable';

import './DetailList.component.scss';

interface Props {
  list: Pick<DetailListType, 'label' | 'value' | 'type'>[];
  filterNil?: boolean;
}
export const DetailList: React.FC<Props> = ({ list, filterNil = false }) => {
  const rows = filterNil ? list.filter((item) => !isNil(item.value)) : list;

  return (
    <Table className="details-list-table">
      {rows.map(({ label, value, type }) => (
        <TableRow key={`dl-${label}`} stack={TableStack.HORIZONTAL}>
          <TableCell percentageWidth={1 / 3} className="details-list-table__key">
            <BodyMedium text={label + ':'} />
          </TableCell>

          <TableCell
            stack={TableStack.VERTICAL}
            className={classNames('details-list-table__value', {
              [`details-list-table__value--${type}`]: type,
            })}
          >
            <LabelMedium text={value} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
