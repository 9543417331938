import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';

import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline, useFeature } from '@sonnen/shared-web';

import {
  getContractType,
  isContractActive,
  isContractTypeAnySonnenFlat,
} from '+app/+customer/+contract/store/+contract.selectors';
import { getMeterInstallation, getSite } from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { CustomerRouteParams } from '+app/router';
import { ContractType } from '+app/shared/store/contract/contract.helpers';
import {
  getSiteConsumedEnergyFormatted,
  getSiteGridPurchasedEnergyFormatted,
  getSiteLiveStateQueryStatus,
  getSiteStatistics,
} from '+app/shared/store/site/site.selectors';
import { FeatureName } from '+config/featureFlags';
import { PageName, Sections } from '+shared/AdobeAnalytics/adobeAnalytics.type';
import { useAdobeAnalyticsTracking } from '+shared/AdobeAnalytics/useAdobeAnalyticsTracking';
import { DetailListTable } from '+shared/components';
import { WarningBox } from '+shared/components/WarningBox';
import { StoreState } from '+shared/store/store.interface';

import { ContractEnergyMeterSection } from '../../components';
import { ContractEnergyMeterError } from '../../components/ContractEnergyMeterError';
import { getContract, getContractQueryStatus } from '../../store/+contract.selectors';
import { factorizeContractDetails } from './../../../../+customer/+overview/components/CustomerContractWidget/CustomerContractWidget.helper';
import { getMaxEnergyAmount } from './CustomerContract.helper';

type Props = ReturnType<typeof mapStateToProps>;
type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const meterInstallation = getMeterInstallation(site);

  return {
    contract: getContract(state),
    contractStatisticStatus: getSiteLiveStateQueryStatus(state),
    totalStatistics: getSiteStatistics(state),
    isAnySonnenFlat: isContractTypeAnySonnenFlat(state),
    contractType: getContractType(state),
    contractStatus: getContractQueryStatus(state),
    isContractActive: isContractActive(state),
    consumedEnergy: getSiteConsumedEnergyFormatted(state),
    gridPurchaseEnergy: getSiteGridPurchasedEnergyFormatted(state),
    meterInstallation,
  };
};

const CustomerContractComponent: React.FC<Props> = ({
  contract,
  isAnySonnenFlat,
  contractStatisticStatus,
  contractStatus,
  isContractActive,
  consumedEnergy,
  gridPurchaseEnergy,
  meterInstallation,
}) => {
  const { useTrackPageLoad } = useAdobeAnalyticsTracking();
  useTrackPageLoad(Sections.CUSTOMERS, PageName.Customers.CUSTOMER_CONTRACT);
  const showContractEnergyMeterSection = useFeature(
    FeatureName.CONTRACT_ENERGY_METER_SECTION
  ).isEnabled;

  if (!contract) return null;
  if (contractStatus.error) return <WarningBox />;

  const { contractType, amountOfFreeEnergy } = contract;
  const list = factorizeContractDetails({ contract, meterInstallation, showProductName: true });
  const total = contractType === ContractType.ENERGY_GLOBAL ? gridPurchaseEnergy : consumedEnergy;

  return (
    <div>
      {isAnySonnenFlat && isContractActive && (
        <>
          {contractStatisticStatus.error && <ContractEnergyMeterError />}
          {total && showContractEnergyMeterSection && (
            <ContractEnergyMeterSection
              maxEnergyAmount={getMaxEnergyAmount(amountOfFreeEnergy)}
              total={total}
              isGrayOut={contractStatisticStatus.error}
            />
          )}
        </>
      )}
      <div className="o-grid">
        <section className="o-grid__column o-grid__column--md-6">
          <PageSubheadline mediumGap={true}>
            {I18n.t(T.customerSingle.contract.sonnenFlat.secondaryHeadline)}
          </PageSubheadline>
          <DetailListTable list={list} filterNil={true} isValueEllipsis={false} />
        </section>
      </div>
    </div>
  );
};

export const CustomerContract = connect(mapStateToProps)(CustomerContractComponent);
