import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { head, isUndefined } from 'lodash/fp';

import { Site } from '+app/shared/store/customer/types/customerSite.type';
import { hasPvSystem } from '+shared/store/battery/battery.helpers';
import { getCaseCollection } from '+shared/store/case/case.selectors';
import {
  isContractSonnenFlat,
  isContractSonnenFlatX,
} from '+shared/store/contract/contract.helpers';
import { Customer } from '+shared/store/customer';
import {
  BatteryOperatingMode,
  BatteryOperatingModeOptions,
} from '+shared/store/site/types/siteLiveState.interface';
import { StoreState } from '+shared/store/store.interface';
import { findElementById } from '+utils/array.util';

import { getBattery, isEatonBattery } from '../+battery/store/+battery.selectors';
import { getPrimaryBatterySystem } from '../+list/store/customer.helpers';

export const getCustomer = (customerId: string) => (collection: Customer[]) =>
  findElementById(collection, customerId);

export const getSite = (siteId?: string) => (customer: Customer | undefined) =>
  !isUndefined(customer)
    ? siteId
      ? findElementById(customer.sites, siteId)
      : head(customer.sites)
    : undefined;

export const getBatteryFromSite = (batteryId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? batteryId
      ? findElementById(site.batterySystems, batteryId)
      : getPrimaryBatterySystem(site)
    : undefined;

export const getContract = (contractId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? contractId
      ? findElementById(site.contracts, contractId)
      : head(site.contracts)
    : undefined;

export const getCharger = (chargerId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? chargerId
      ? findElementById(site.chargers, chargerId)
      : head(site.chargers)
    : undefined;

export const getMeterInstallation = (site?: Site) =>
  site && site.contracts && site.contracts[0] && site.contracts[0].meterInstallation;

export const getAssetsAvailability = (site: Site | undefined, state: StoreState) => {
  const battery = getBattery(state);
  const contract = getContract()(site);
  const cases = getCaseCollection(state);
  const charger = getCharger()(site);
  const isEaton = isEatonBattery(state);
  const sonnenFlat = contract ? isContractSonnenFlat(contract) : false;
  const sonnenFlatX = contract ? isContractSonnenFlatX(contract) : false;

  return {
    hasBattery: !!battery,
    hasEatonBattery: !!isEaton,
    hasContract: !!contract,
    hasPvSystem: hasPvSystem(battery),
    hasCases: !!cases.length,
    hasCharger: !!charger,
    hasSonnenFlat: !!sonnenFlat,
    hasSonnenFlatX: !!sonnenFlatX,
  };
};

const {
  selfConsumption,
  moduleExtension,
  timeOfUse,
  optimization,
  cellBalancing,
  backupPower,
  automatic,
  finalInspection,
  manual,
} = T.customerSingle.batteryDetails.batteryLive.operatingMode;

const operatingModeLabels: Partial<Record<BatteryOperatingModeOptions, string>> = {
  [BatteryOperatingModeOptions.SELF_CONSUMPTION]: selfConsumption,
  [BatteryOperatingModeOptions.MODULE_EXTENSION]: moduleExtension,
  [BatteryOperatingModeOptions.US_TIME_OF_USE]: timeOfUse,
  [BatteryOperatingModeOptions.OPTIMIZATION]: optimization,
  [BatteryOperatingModeOptions.CELL_BALANCING]: cellBalancing,
  [BatteryOperatingModeOptions.US_BACKUP_POWER]: backupPower,
  [BatteryOperatingModeOptions.US_AUTOMATIC]: automatic,
  [BatteryOperatingModeOptions.INTELLIGENT_MODE]: finalInspection,
  [BatteryOperatingModeOptions.FINAL_INSPECTION]: finalInspection,
  [BatteryOperatingModeOptions.MANUAL]: manual,
};

export const mapBatteryOperatingModeToLabel = (
  batteryOperatingMode?: BatteryOperatingMode
): string => {
  if (!batteryOperatingMode || !operatingModeLabels[batteryOperatingMode]) return '-';

  return I18n.t(operatingModeLabels[batteryOperatingMode]!);
};
