import { T } from '@sonnen/shared-i18n/service';

import { BatteryOperatingModeOptions } from '+shared/store/site/types';

type InfoWarning = {
  info: string;
  warning: string;
};

const {
  changeToModuleExtension,
  changeToOptimization,
  changeToSelfConsumptionText,
  changeToTimeOfUse,
  extensionModeWarning,
  selfConsumptionWarning,
  timeOfUseWarning,
  optimizationWarning,
} = T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.confirmationModal;

const labelMap: Partial<Record<BatteryOperatingModeOptions, InfoWarning>> = {
  [BatteryOperatingModeOptions.SELF_CONSUMPTION]: {
    info: changeToSelfConsumptionText,
    warning: selfConsumptionWarning,
  },
  [BatteryOperatingModeOptions.MODULE_EXTENSION]: {
    info: changeToModuleExtension,
    warning: extensionModeWarning,
  },
  [BatteryOperatingModeOptions.US_TIME_OF_USE]: {
    info: changeToTimeOfUse,
    warning: timeOfUseWarning,
  },
  [BatteryOperatingModeOptions.OPTIMIZATION]: {
    info: changeToOptimization,
    warning: optimizationWarning,
  },
};

export const getInfoAndWarning = (
  activeOperatingMode: BatteryOperatingModeOptions | undefined | null
): InfoWarning => {
  if (
    !activeOperatingMode ||
    !labelMap[activeOperatingMode] ||
    !labelMap[activeOperatingMode]?.info
  )
    return {
      info: '',
      warning: '',
    };

  return labelMap[activeOperatingMode]!;
};
